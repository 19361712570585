<template>
    <section>
        <div class="col-md-9 col-10" style="margin: auto">
            <v-row>
                <v-col cols="12" md="4" sm="6" v-for="(tool, i) in tools" :key="i">
                    <v-card
                        class="mx-auto text-center justify-center"
                        max-width="400"
                        flat
                        color="transparent"
                    >
                        <v-icon size="150px" color="primary" class="mb-0 pb-0"
                            >{{ tool.icon }}
                        </v-icon>
                        <v-card-title class="justify-center mt-0 pt-0">{{
                            tool.title
                        }}</v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn color="red white--text" disabled>
                                <v-icon>mdi-open-in-new</v-icon>
                                <span class="mr-2">Soon!</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
export default {
    name: "ToolCard",
    props: ["tools"]
};
</script>
