<template>
    <v-img :src="Image" height="100vh">
        <div class="svg-border-waves">
            <img src="@/assets/img/borderWaves.svg" />
        </div>
    </v-img>
</template>

<script>
export default {
    name: "HeaderImage",
    props: ["Image"]
};
</script>

<style lang="scss" scoped>
.v-image > .v-responsive__content > .svg-border-waves > img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}
</style>
