<template>
    <v-app>
        <AppBar
            :color="color"
            :flat="flat"
            :drawer="drawer"
            :customClass="customClass"
            :roles="roles"
            v-if="!$route.meta.hide_toolbar"
        />
        <v-main class="pt-0">
            <router-view :roles="roles" />
        </v-main>
        <MobileNav v-if="!$route.meta.hide_toolbar" />
        <v-scale-transition>
            <v-btn
                fab
                v-show="fab"
                v-scroll="onScroll"
                dark
                fixed
                bottom
                right
                color="secondary"
                @click="toTop()"
            >
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
        </v-scale-transition>
        <AppFooter v-if="!$route.meta.hide_toolbar" />
    </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import AppFooter from "./components/AppFooter";
import MobileNav from "./components/MobileNavigation";
import axios from "axios";

export default {
    name: "App",

    components: {
        AppBar,
        AppFooter,
        MobileNav
    },

    data: () => ({
        fab: null,
        color: "",
        flat: null,
        isXs: false,
        drawer: false,
        customClass: "",
        roles: "",
        type: "error",
        message: "",
        show: false
    }),

    async created() {
        const top = window.pageYOffset || 0;
        if (top <= 60) {
            this.color = "transparent";
            this.flat = true;
        }
        try {
            let profile = await axios.get("/api/profile", {
                withCredentials: true
            });
            this.roles = profile.data.role;
        } catch (error) {
            console.log("Unauthenticated");
        }
    },

    watch: {
        fab(value) {
            if (value) {
                this.color = "primary";
                this.flat = false;
            } else {
                this.color = "transparent";
                this.flat = true;
            }
        },
        isXs(value) {
            if (value) {
                this.customClass = "justify-center mobileWidth";
            } else {
                this.customClass = "";
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onResize() {
            this.isXs = window.innerWidth < 850;
        }
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
        this.$root.$on("reset-roles", (data) => {
            this.roles = "";
        });
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Aguafina+Script&display=swap");

html {
    overflow-y: auto;
}
.markdown-body > pre > code {
    background-color: initial;
    color: inherit;
}
.theme--light.v-application code {
    color: red;
}
</style>
<style scoped>
.v-main {
    background-image: url("~@/assets/img/bgMain.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
</style>
