import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueTypedJs from "vue-typed-js";
import ScrollAnimation from "./directives/scrollanimation";
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";

Vue.config.productionTip = false;
Vue.use(VueTypedJs);

const toast_options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    toastDefaults: {
        [TYPE.ERROR]: {
            timeout: 1500,
            pauseOnFocusLoss: false,
            pauseOnHover: false
        },
        [TYPE.SUCCESS]: {
            timeout: 1500,
            pauseOnFocusLoss: false,
            pauseOnHover: false
        },
        [TYPE.INFO]: {
            timeout: 1800,
            pauseOnFocusLoss: false,
            pauseOnHover: false
        }
    }
};
Vue.use(Toast, toast_options);

Vue.directive("scrollanimation", ScrollAnimation);

router.beforeEach(async (to, from, next) => {
    if (to.meta.restrictedarea || to.name == "BossArea") {
        try {
            let resp = await axios.get("/api/profile", {
                withCredentials: true
            });
            resp.status == 200 && resp.data.roles != "" && to.name != "BossArea"
                ? next()
                : next("/home");
        } catch (error) {
            to.name == "BossArea" ? next() : next("/home");
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    document.title = to.meta.title + " | TukangKetik";
    window.scroll(0, 0);
    if (to.name != "Notes" && to.name != "NoteDetails") {
        sessionStorage.clear();
    }
});

new Vue({
    router,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
