<template>
    <v-footer v-bind="localAttrs" :padless="padless">
        <v-card flat tile width="100%" class="primary dark text-center justify-center">
            <img class="waves-footer" src="@/assets/img/borderWaves.svg" />
            <v-card-text class="mt-10">
                <v-btn
                    v-for="(item, index) in contacts"
                    :key="index"
                    class="mx-4"
                    icon
                    :href="item.link"
                    target="_blank"
                >
                    <v-icon size="24px">
                        {{ item.icon }}
                    </v-icon>
                </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text"> 2021 — <strong>TukangKetik.net</strong> </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: "AppFooter",
    data: () => ({
        contacts: [
            { icon: "mdi-facebook", link: "https://www.facebook.com/frazi19/" },
            { icon: "mdi-instagram", link: "https://www.instagram.com/razi_fr19/" },
            { icon: "mdi-linkedin", link: "https://www.linkedin.com/in/fakhrur-razi/" },
            { icon: "mdi-twitter", link: "https://twitter.com/__FakhrurRazi__" }
        ],
        padless: true,
        variant: "default"
    }),
    computed: {
        localAttrs() {
            const attrs = {};

            if (this.variant === "default") {
                attrs.absolute = false;
                attrs.fixed = false;
            } else {
                attrs[this.variant] = true;
            }
            return attrs;
        }
    }
};
</script>

<style scoped>
.v-divider {
    max-width: 50%;
    margin: auto;
}
.waves-footer {
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}
</style>
