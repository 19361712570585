<template>
    <v-dialog :value="dialog" max-width="350" @click:outside="confirmed(false)">
        <v-form ref="form-new-category">
            <v-card>
                <v-col cols="12" md="12" sm="12" align="center">
                    <v-icon size="150px" color="orange" class="mb-0 pb-0"
                        >mdi-alert-circle-outline
                    </v-icon>
                </v-col>
                <v-card-title class="pb-0">
                    <v-col cols="12" md="12" sm="12" align="center"> Are You F*cking Sure? </v-col>
                </v-card-title>
                <v-card-text align="center" class="mt-0">
                    <v-row>
                        <v-col cols="6" md="6" sm="6">
                            <v-btn class="" color="green white--text" @click="confirmed(false)">
                                <v-icon dark left> mdi-cancel </v-icon> Just Kidding
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="6" sm="6">
                            <v-btn class="" color="red white--text" @click="confirmed(true)">
                                <v-icon dark left> mdi-alert-circle </v-icon> Yes I am
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmationDialog",
    props: ["dialog"],
    methods: {
        confirmed(resp) {
            this.$root.$emit("confirmed", resp);
        }
    }
};
</script>

<style></style>
