<template>
    <div>
        <v-app-bar app :color="color" :flat="flat" dark class="px-15" :class="{ expand: flat }">
            <div :class="`d-flex align-center ${customClass}`">
                <v-img
                    alt="TK Logo"
                    class="shrink mr-2"
                    contain
                    src="@/assets/img/TukangKetik.png"
                    transition="scale-transition"
                    width="40"
                />

                <label class="text-h5 text-md-h3 font-weight-black"
                    >Tukang<font color="black">Ketik</font></label
                >
            </div>

            <v-spacer></v-spacer>
            <div class="hidden-sm-and-down">
                <v-btn @click="$router.push('/home').catch(() => {})" text>
                    <v-icon>mdi-home</v-icon>
                    <span class="mr-1">Home</span>
                </v-btn>
                <template v-if="homePage()">
                    <v-btn @click="$vuetify.goTo('#notes')" text>
                        <v-icon>mdi-note-text-outline</v-icon>
                        <span class="mr-1">Notes</span>
                    </v-btn>
                    <v-btn @click="$vuetify.goTo('#tools')" text>
                        <v-icon>mdi-code-tags</v-icon>
                        <span class="mr-1">Tools</span>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn @click="$router.push('/notes').catch(() => {})" text>
                        <v-icon>mdi-note-text-outline</v-icon>
                        <span class="mr-1">Notes</span>
                    </v-btn>
                    <v-btn @click="$router.push({ name: 'Home', hash: '#tools' })" text>
                        <v-icon>mdi-code-tags</v-icon>
                        <span class="mr-1">Tools</span>
                    </v-btn>
                </template>
                <v-btn @click="$router.push('/about').catch(() => {})" text>
                    <v-icon>mdi-account-box-outline</v-icon>
                    <span class="mr-1">About Me</span>
                </v-btn>
                <template v-if="roles == ''">
                    <v-btn @click="$router.push('/bossarea').catch(() => {})" text>
                        <v-icon>mdi-login</v-icon>
                        <span class="mr-1">Login</span>
                    </v-btn>
                </template>
                <template v-else>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                <v-icon>mdi-cog-outline</v-icon>
                                <span>Manage </span>
                            </v-btn>
                        </template>
                        <v-list shaped>
                            <v-list-item-group color="primary">
                                <v-list-item
                                    v-for="(item, index) in admin_menus"
                                    :key="index"
                                    :to="item.path"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-btn @click="logout()" text>
                        <v-icon>mdi-logout</v-icon>
                        <span class="mr-2">Logout</span>
                    </v-btn>
                </template>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "AppBar",
    props: {
        color: String,
        flat: Boolean,
        drawer: Boolean,
        customClass: String,
        roles: String
    },
    data: () => ({
        items: [
            ["mdi-home-outline", "Home", "#hero"],
            ["mdi-information-outline", "Sobre", "#features"],
            ["mdi-download-box-outline", "Download", "#download"],
            ["mdi-currency-usd", "Preços", "#pricing"],
            ["mdi-email-outline", "Contatos", "#contact"]
        ],
        admin_menus: [
            { title: "Notes", path: "/add/note", icon: "mdi-plus" },
            { title: "Achievements", path: "/add/achievement", icon: "mdi-plus" },
            { title: "Tools", path: "/tools", icon: "mdi-plus" }
        ]
    }),
    methods: {
        homePage() {
            if (this.$route.path == "/" || this.$route.path == "/home") {
                return true;
            } else {
                return false;
            }
        },
        async logout() {
            let resp = await axios.get("/api/logout", {
                withCredentials: true
            });
            if (resp.status == 200) {
                this.$root.$emit("reset-role", "");
                this.$toast.success("Logged Out", {
                    onClose: () => {
                        this.$router.go();
                    }
                });
            }
        }
    },
    async created() {}
};
</script>

<style scoped>
.v-toolbar {
    transition: 0.6s;
}

.expand {
    height: 80px !important;
    padding-top: 10px;
}
.mobileWidth {
    width: 100vh;
}
</style>
