<template>
    <div class="hidden-md-and-up">
        <v-menu top :offset-y="true" transition="scroll-y-reverse-transition">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    fixed
                    bottom
                    right
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    class="mobileMenu"
                    ><v-icon>mdi-menu</v-icon>
                </v-btn>
            </template>

            <v-list shaped>
                <v-list-item-group color="primary">
                    <v-list-item v-for="(item, index) in items" :key="index" :to="item.path">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "MobileNav",
    data: () => ({
        items: [
            { title: "Home", path: "/", icon: "mdi-home" },
            { title: "Notes", path: "/notes", icon: "mdi-note-text-outline" },
            { title: "Tools", path: "/home#tools", icon: "mdi-code-tags" },
            { title: "About Me", path: "/about", icon: "mdi-account-box-outline" }
        ]
    })
};
</script>

<style scoped>
.mobileMenu {
    margin-bottom: 60px;
}
</style>
