import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        component: Home,
        meta: {
            title: "Homepage"
        }
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            title: "Homepage"
        }
    },
    {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
        meta: {
            title: "About Me"
        }
    },
    {
        path: "/notes",
        name: "Notes",
        component: () => import("../views/Notes.vue"),
        meta: {
            title: "Boss Notes"
        }
    },
    {
        path: "/note/:id",
        name: "NoteDetails",
        component: () => import("../views/NoteDetail.vue"),
        meta: {
            hide_toolbar: true
        }
    },
    {
        path: "/add/note",
        name: "AddNote",
        component: () => import("../views/NoteForm.vue"),
        meta: {
            title: "Add Note",
            hide_toolbar: true,
            restrictedarea: true
        }
    },
    {
        path: "/edit/note/:id",
        name: "EditNote",
        component: () => import("../views/NoteForm.vue"),
        meta: {
            title: "Edit Note",
            hide_toolbar: true,
            restrictedarea: true
        }
    },
    {
        path: "/bossarea",
        name: "BossArea",
        component: () => import("../views/login.vue"),
        meta: {
            hide_toolbar: true,
            title: "Boss Area"
        }
    },
    {
        path: "/add/achievement",
        name: "AddAchievement",
        component: () => import("../views/AchievementForm.vue"),
        meta: {
            title: "Add Achievement",
            hide_toolbar: true,
            restrictedarea: true
        }
    },
    {
        path: "/edit/achievement/:id",
        name: "EditAchievement",
        component: () => import("../views/AchievementForm.vue"),
        meta: {
            title: "Edit Achievement",
            hide_toolbar: true,
            restrictedarea: true
        }
    },
    {
        path: "/edit/about/:id",
        name: "EditAbout",
        component: () => import("../views/AboutForm.vue"),
        meta: {
            title: "Edit About",
            hide_toolbar: true,
            restrictedarea: true
        }
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("../views/NotFound.vue"),
        meta: {
            hide_toolbar: true,
            title: "404 Not Found"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
