<template>
    <div class="home">
        <HeaderImage :Image="Image" />
        <v-container>
            <v-row class="text-center" id="notes">
                <v-col class="mb-0">
                    <div class="text">
                        <span>N</span>
                        <span>O</span>
                        <span>T</span>
                        <span>E</span>
                        <span>S</span>
                    </div>
                </v-col>
            </v-row>
            <NotesCard :notes="notes" :skeleton="skeleton" :roles="roles" />
            <v-row class="justify-center mt-5">
                <v-btn color="red white--text" to="/notes"
                    ><v-icon>mdi-magnify</v-icon>Find More</v-btn
                >
            </v-row>
        </v-container>
        <div class="polygon">
            <v-parallax dark :src="'/assets/img/lapcode.png'" class="mt-15" id="tools">
                <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12">
                        <h1 class="text-md-h2 text-h3 font-weight-bold mb-4">Tools</h1>
                        <h4 class="subheading">Some Shits By BossTK</h4>
                    </v-col>
                </v-row>
            </v-parallax>
        </div>
        <ToolCard :tools="tools" />
    </div>
</template>

<script>
// @ is an alias to /src
import HeaderImage from "@/components/HeaderImage.vue";
import NotesCard from "@/components/NotesCard.vue";
import ToolCard from "@/components/ToolCard.vue";
import axios from "axios";

export default {
    name: "Home",
    components: {
        HeaderImage,
        NotesCard,
        ToolCard
    },
    props: ["roles"],
    data: () => ({
        Image: "/assets/img/home.png",
        skeleton: false,
        notes: [],
        tools: [
            {
                title: "Encode Decode",
                icon: "mdi-shield-key-outline"
            },
            {
                title: "Scanner",
                icon: "mdi-magnify-scan"
            },
            {
                title: "Forensic",
                icon: "mdi-onepassword"
            },
            {
                title: "Bug Hunting",
                icon: "mdi-shield-bug-outline"
            },
            {
                title: "Hardening",
                icon: "mdi-shield-check-outline"
            },
            {
                title: "Red Teaming",
                icon: "mdi-sword-cross"
            }
        ]
    }),
    async created() {
        this.skeleton = true;
        let response = await axios.post(
            "/api/notes",
            { start: 0, count: 3 },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        this.skeleton = false;
        this.notes = this.notes.concat(response.data);
    },
    mounted() {
        if (this.$route.hash) {
            this.$vuetify.goTo(this.$route.hash, { duration: 0 });
        }
    }
};
</script>

<style lang="scss" scoped>
.text {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    transform: translateY(-50%);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    font-size: 48px;
    font-family: "Times New Roman", Times, serif;

    &:hover {
        transform: rotateX(35deg), translateY(-50%);
        span {
            color: #ccc;
            &:nth-child(odd) {
                transform: skewY(15deg);
                // background-color: #f00;
                // box-shadow: 0 60px 20px rgba(0,0,0,0.1);
            }
            &:nth-child(even) {
                transform: skewY(-15deg);
                background-color: #f9f9f9;
                color: #a6a6a6;
                // box-shadow: 0 60px 20px rgba(0,0,0,0.1);
            }
        }
    }

    > span {
        display: block;
        background-color: #fff;
        width: 120px;
        height: 120px;
        line-height: 120px;
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;
        box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
        &:first-child {
            border-radius: 5px 0 0 5px;
        }
    }
}
</style>

<style scoped>
.polygon {
    clip-path: polygon(50% 0, 100% 10%, 100% 90%, 50% 100%, 0 90%, 0 10%);
}
</style>
