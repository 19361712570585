<template>
    <section>
        <div class="col-md-9 col-10" style="margin: auto">
            <v-row>
                <v-col cols="12" md="4" sm="6" v-for="(note, i) in notes" :key="i">
                    <v-card class="mx-auto card-outter" max-width="400" height="100%" style="">
                        <v-img
                            class="white--text align-end"
                            height="200px"
                            width="400px"
                            :src="getImageURL(note.img)"
                        ></v-img>
                        <v-card-title>{{ note.title }}</v-card-title>
                        <v-card-subtitle class="pb-0">
                            <v-icon>mdi-account</v-icon>
                            {{ note.author }}
                        </v-card-subtitle>
                        <v-card-subtitle class="pt-0 pb-0">
                            <v-icon>mdi-calendar-month</v-icon>
                            {{ note.date }}
                        </v-card-subtitle>
                        <v-card-subtitle class="pt-0">
                            <v-icon>mdi-label</v-icon>
                            {{ note.category }}
                        </v-card-subtitle>
                        <v-card-actions class="card-actions px-0">
                            <v-row class="mx-0 px-0">
                                <v-col cols="12" sm="12" md="12" class="px-0 mx-0">
                                    <v-btn color="orange" text :to="`/note/${note.id}`">
                                        <v-icon>mdi-book-open-variant</v-icon>
                                        <span>Read More</span>
                                    </v-btn>
                                    <template v-if="roles != ''">
                                        <template v-if="$vuetify.breakpoint.mdAndUp ? true : false">
                                            <v-btn color="green" text :to="`/edit/note/${note.id}`">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span>Edit</span>
                                            </v-btn>
                                            <v-btn color="red" text @click="deleteNote(note.id)">
                                                <v-icon>mdi-trash-can</v-icon>
                                                <span>Delete</span>
                                            </v-btn>
                                        </template>
                                        <div class="hidden-md-and-up adminmenu" width="30px">
                                            <v-menu
                                                transition="scroll-y-reverse-transition"
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        dark
                                                        text
                                                        right
                                                        color="red"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        max-width="30px"
                                                        ><v-icon>mdi-menu</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list shaped>
                                                    <v-list-item-group color="primary">
                                                        <v-list-item @click="editNote(note.id)">
                                                            <v-list-item-icon>
                                                                <v-icon color="green"
                                                                    >mdi-pencil</v-icon
                                                                >
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    >Edit</v-list-item-title
                                                                >
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="deleteNote(note.id)">
                                                            <v-list-item-icon>
                                                                <v-icon color="red"
                                                                    >mdi-trash-can</v-icon
                                                                >
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    >Delete</v-list-item-title
                                                                >
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" v-show="nodata">
                    <v-card class="mx-auto pt-3" max-width="600">
                        <v-img
                            class="white--text align-end"
                            width="600px"
                            src="@/assets/img/nodata.png"
                        ></v-img>
                        <v-card-title class="pb-0 justify-center text-h5 text-md-h3"
                            >4 0 4</v-card-title
                        >
                        <v-card-title class="pt-0 justify-center text-h5 text-md-h3"
                            >NOT FOUND</v-card-title
                        >
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" v-show="skeleton">
                    <v-sheet>
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="400"
                            type="card, list-item-three-line, button"
                        ></v-skeleton-loader>
                    </v-sheet>
                </v-col>
                <v-col cols="12" sm="4" v-show="skeleton">
                    <v-sheet>
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="400"
                            type="card, list-item-three-line, button"
                        ></v-skeleton-loader>
                    </v-sheet>
                </v-col>
                <v-col cols="12" sm="4" v-show="skeleton">
                    <v-sheet>
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="400"
                            type="card, list-item-three-line, button"
                        ></v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
        <Confirmation :dialog="dialog" />
    </section>
</template>

<script>
import axios from "axios";
import Confirmation from "@/components/Confirmation.vue";
export default {
    name: "NotesCard",
    props: ["notes", "skeleton", "nodata", "roles"],
    components: {
        Confirmation
    },
    data: () => ({
        dialog: false,
        confirmed: false,
        toDelete: ""
    }),
    methods: {
        getImageURL(img) {
            return "/assets/img/" + img;
        },
        async deleteAction(id) {
            this.$toast.info("Deleting Note...");
            try {
                let resp = await axios.delete("/api/notes/" + id, {
                    withCredentials: true
                });
                if (resp.status == 204) {
                    this.$toast.success("Note Deleted", {
                        onClose: () => {
                            sessionStorage.clear();
                            this.$router.go();
                        }
                    });
                } else {
                    this.$toast.error("Failed to Remove Note");
                }
            } catch (error) {
                this.$toast.error("Failed to Remove Note");
            }
        },
        editNote(id) {
            this.$router.push("/edit/note/" + id);
        },
        deleteNote(id) {
            this.dialog = true;
            this.toDelete = id;
        }
    },
    mounted() {
        this.$root.$on("confirmed", (data) => {
            if (data) {
                this.deleteAction(this.toDelete);
                this.toDelete = "";
                this.dialog = false;
            } else {
                this.dialog = false;
            }
        });
    },
    beforeDestroy() {
        this.$root.$off("confirmed");
    }
};
</script>

<style scoped>
.markdown-body {
    margin: auto;
}
.card-outter {
    position: relative;
    padding-bottom: 50px;
}
.card-actions {
    position: absolute;
    bottom: 0;
}
.adminmenu {
    float: right;
}
</style>
